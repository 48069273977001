import React, { useEffect } from 'react';
import { Box } from '@material-ui/core/';
import ROUTES from '../routes';
import Backdrop from '../components/backdrop';
import { navigate } from '@reach/router';
import useAuth from '../hooks/useAuth';

export const OCPARedirect = () => {
  const { isAuthenticated, isLoading } = useAuth();

  useEffect(() => {
    if (!isLoading) {
      void navigate(
        isAuthenticated
          ? ROUTES.OREGON_CONSUMER_PRIVACY_ACT
          : ROUTES.OREGON_CONSUMER_PRIVACY_ACT_PUBLIC_FORM,
        { replace: true },
      );
    }
  }, [isAuthenticated, isLoading]);

  return (
    <Box css={{ minHeight: '50vh' }}>
      <Backdrop forceOpen={true} />
    </Box>
  );
};

export default OCPARedirect;
